.u-text-left {
	text-align: left;
}
.u-text-right {
	text-align: right;
}
.u-text-center {
	text-align: center;
}
.u-text-justify {
	text-align: justify;
}
.u-text-nowrap {
	white-space: nowrap;
}
.u-text-lowercase {
	text-transform: lowercase;
}
.u-text-uppercase {
	text-transform: uppercase;
}
.u-text-capitalize {
	text-transform: capitalize;
}
.u-font-light {
	font-weight: 300;
}
.u-font-regular {
	font-weight: normal;
}
.u-font-bold {
	font-weight: bold;
}
.u-font-italic {
	font-style: italic;
}
.u-nowrap {
	white-space: nowrap;
}

.u-text-small {
	font-size: 0.8rem;
}
