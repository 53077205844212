.u-mt-sm {
	margin-top: $gutter / 2;
}
.u-mt-md {
	margin-top: $gutter;
}
.u-mt-lg {
	margin-top: $gutter * 2;
}
.u-mt-xl {
	margin-top: $gutter * 3;
}

.u-mb-sm {
	margin-bottom: $gutter / 2;
}
.u-mb-md {
	margin-bottom: $gutter;
}
.u-mb-lg {
	margin-bottom: $gutter * 2;
}
.u-mb-xl {
	margin-bottom: $gutter * 3;
}

.u-pt-sm {
	padding-top: $gutter / 2;
}
.u-pt-md {
	padding-top: $gutter;
}
.u-pt-lg {
	padding-top: $gutter * 2;
}
.u-pt-xl {
	padding-top: $gutter * 3;
}

.u-pb-sm {
	padding-bottom: $gutter / 2;
}
.u-pb-md {
	padding-bottom: $gutter;
}
.u-pb-lg {
	padding-bottom: $gutter * 2;
}
.u-pb-xl {
	padding-bottom: $gutter * 3;
}

