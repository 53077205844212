.form-filter {
	padding: 1.25rem;
	background-color: $colorBd;
	&__inner {
		margin-bottom: -1.25rem;
	}

	.input {
		padding: 0.625rem 1.875rem;
		background-color: white;
		border: 0.0625rem solid white;	
		margin-bottom: 0;
	}

	.button {
		padding: 0.625rem 1.875rem;
		line-height: 1.5rem;
	}
}

.form-profile {
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: space-between;
		margin-left: -2.5rem;
		margin-bottom: -1.25rem;
	}
	&__item {
		flex: 0 1 50%;
		border-left: 2.5rem solid transparent;
		border-bottom: 1.25rem solid transparent;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
	}
}

.form-request {
	&__wrap {
		padding: 1.25rem;
		border: 0.0625rem solid $colorBd;
	}
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: space-between;
		margin-left: -2.5rem;
		margin-bottom: -1.25rem;
	}
	&__item {
		flex: 0 1 50%;
		border-left: 2.5rem solid transparent;
		border-bottom: 1.25rem solid transparent;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
	}
	&__more {
		position: absolute;
		left: 1.25rem;
		bottom: 1.25rem;
		margin-bottom: 0;
	}

	// Variants
	&__wrap--with-btn {
		position: relative;
		padding-bottom: 4.125rem;
	}
	&__item--full {
		flex: 0 1 100%;
	}
	&__item--top {
		align-items: flex-start;
	}
}

.search {
	position: absolute;
	right: 1.25rem;
	top: 4.7rem;
	width: 3.375rem;
	overflow: hidden;
	transition: width 0.5s;
	&__form {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: flex-end;
	}
	&__open {
		position: absolute;
		z-index: 100;
		top: 0;
		right: 0;
		width: 3.375rem;
		height: 3.375rem;
		padding: 0;
		background-color: $colorPrimary;
		color: $colorWhite;
		transition: background $t, color $t;
		cursor: pointer;
	}
	&__close {
		position: absolute;
		z-index: 100;
		top: 0;
		right: 0;
		width: 3.375rem;
		height: 3.375rem;
		padding: 0;
		background-color: $colorSecondary;
		color: $colorPrimary;
		transition: background $t, color $t;
		cursor: pointer;
	}

	// States
	.hoverevents &__open {
		&:hover {
			background-color: $colorSecondary;
			border: 0.0625rem solid $colorPrimary;
			color: $colorPrimary;
		}
	}
	.hoverevents &__close {
		&:hover {
			background-color: $colorTertiary;
			color: $colorBlack;
		}
	}
	&.is-open {
		overflow: auto;
		width: calc(100% - 2.5rem);
	}

	// Media
	@media ($lgDown) {
		right: 3.4375rem;
		bottom: 0;
		width: 2.125rem;
		height: 2.125rem;
		&--loggedin {
			right: 5.625rem;
		}
		&__open,
		&__close {
			width: 2.125rem;
			height: 2.125rem;
		}
		&.is-open {
			width: 24.25rem;
		}
	}

	@media (max-width: 585px) {
		&.is-open {
			width: 15.3125rem;
		}
		&--loggedin.is-open {
			width: 13.125rem;
		}
	}
}
