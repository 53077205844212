html {
	color: $colorText;
	font-family: $fontPrimary;
	font-size: $fontSize;
	line-height: $lineHeight;
	font-weight: $regular;
}

// Headings
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.form-title {
	font-family: $fontSecondary;
	font-weight: $regular;
	line-height: 1.2;
	color: $colorPrimary;
}

h1,
.h1 {
	margin: 2.5rem 0 2rem;
	font-size: 1.25rem;
	border-bottom: 0.0625rem solid $colorPrimary;
}

h2,
.h2 {
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
}

h3,
.h3 {
	margin: 1.5rem 0 1rem;
	font-size: 1.125rem;
}

h4,
.h4 {
	margin: 1.5rem 0 1rem;
	font-size: 1rem;
}

.form-title {
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	font-weight: $semiBold;
	color: $colorText;
}

// Paragraph
p {
	margin: 0 0 $spaceVertical;
	text-align: justify;
}

strong {
	font-weight: $bold;
}

hr {
	height: 0.0625rem;
	margin: $spaceVertical 0;
	border: none;
	border-top: 0.0625rem solid $colorBd;
	overflow: hidden;
}

// Blockquote
blockquote {
	margin: 0 0 $spaceVertical;
	padding: 0 0 0 $spaceVertical;
	border-left: 0.1875rem solid $colorBd;
	color: $colorText;

	p {
		margin-bottom: 0;
	}
}

// Links
a {
	position: relative;
	color: $colorLink;
	text-decoration: none;
	transition: color $t;
	-webkit-tap-highlight-color: transparent;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		right: 50%;
		width: 0;
		bottom: 0.125rem;
		height: 0.0625rem;
		background-color: $colorPrimary;
		transition: left $t, right $t;
	}

	.hoverevents &:hover {
		&::after {
			left: 0;
			right: 0;
			width: auto;
		}
	}
}

// Lists
ul,
ol,
dl {
	margin: 0 0 $spaceVertical;
	padding: 0;
	list-style: none;
}

li {
	margin: 0 0 ($spaceVertical / 4);
	padding: 0 0 0 $spaceVertical;
}

ul {
	li {
		background-image: url($svgBullet);
		background-position: 0.1875rem 0.6rem;
		background-repeat: no-repeat;
		background-size: 0.25rem 0.25rem;
	}

	&.--neutral {
		li {
			background: none;
			padding: 0;
			margin: 0;
		}
	}
}

ol {
	counter-reset: item;

	li {
		position: relative;

		&::before {
			content: counter(item) '.';
			counter-increment: item;
			position: absolute;
			top: 0;
			left: 0;
			font-weight: $bold;
		}
	}
}

ol.--letter {
	li {
		&::before {
			content: counter(item, lower-alpha) ')';
			counter-increment: item;
			font-weight: normal;
		}
	}
}

dt {
	margin: 0;
	font-weight: bold;
}

dd {
	margin: 0 0 ($spaceVertical / 2);
	padding: 0;
}

// Tables
.table-wrap {
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}

table {
	th {
		text-align: left;
	}
}

.table-designed {
	clear: both;
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
	width: 100%;
	min-width: 46.875rem;
	margin: 0 0 $spaceVertical;
	border: 0.0625rem solid $colorBd;

	caption {
		padding: 0 0 0.625rem;
		font-weight: bold;
		text-align: left;
		caption-side: top;
	}

	td,
	th {
		vertical-align: top;
		padding: 0.9375rem 1.25rem;
		border: 0.0625rem solid $colorBd;
	}

	th {
		font-weight: bold;
		text-align: left;
	}

	thead th {
		background-color: #e8edf4;
	}
}

// Image
figure {
	margin-bottom: $spaceVertical;
}

figcaption {
	margin-top: 0.5em;
}

img {
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.table-clean {
	width: auto;
	min-width: auto;
	margin: 0;
	border: 0;

	td,
	th {
		padding: 0;
		border: 0;
	}
}