// Mq checker
@mixin mq-checker($breakpoint, $type: 'Up') {
	@if (map-has-key($breakpointsVars, $breakpoint + $type)) {
		@media (map-get($breakpointsVars, $breakpoint + $type)) {
			@content;
		}
	} @else {
		@error 'Unfortunately, breakpoint `#{$breakpoint}` is not defined in config.js';
	}
}

// Suffix
@mixin suffix($suffix, $delimiter: '\\@') {
	&#{$delimiter}#{$suffix} {
		@content;
	}
}

// Breakpoints
@mixin generateBreakpoints($breakpoints: $breakpoints) {
	@content;
	@each $breakpoint, $value in $breakpoints {
		@include mq-checker($breakpoint) {
			@include suffix($breakpoint) {
				@content;
			}
		}
	}
}

// Clearfix
@mixin clearfix() {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}

// Hiding content
@mixin vhide() {
	position: absolute;
	width: 0.0625rem;
	height: 0.0625rem;
	margin: -0.0625rem;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

// Fancybox
@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}
