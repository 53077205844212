.u-bg-default {
	background-color: $colorBg;
}
.u-bg-primary {
	background-color: $colorPrimary;
}
.u-bg-secondary {
	background-color: $colorSecondary;
}
.u-bg-red-light {
	background-color: $colorRedLight;
}
.u-bg-blue-light {
	background-color: rgba($colorPrimary, 0.05);;
}
