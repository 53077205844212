.u-align-top {
	vertical-align: top;
}
.u-align-middle {
	vertical-align: middle;
}
.u-align-bottom {
	vertical-align: bottom;
}
.u-margin-left {
	margin-left: auto;
}
.u-margin-right {
	margin-right: auto;
}
