@import "variables";
@import 'base/variables';

// Stylopisy našeho frameworku
@import "mixins";
@import "functions";
@import "vendors/*.scss";

// Stylopisy od externího dodavatele
@import 'base/index';
@import 'core/index';
@import 'components/index';
@import 'utilities/index';

@import "../app/components/**/*.scss";
@import "../app/modules/**/*.scss";

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	position: relative;
	min-width: 20rem;
}

:first-child {
	margin-top: 0;
}

.anchor {
	visibility: hidden;
	position: relative;
	top: -2rem;
}

address {
	font-style: normal;
}

*:focus {
	outline: none !important;
}

.mb-formitem {
	margin-bottom: 2.2rem;
}

.mb-gutter {
	margin-bottom: 20px;
}

/* "Neviditelná" základní tabulka */
.table-transparent {

	td,
	th {
		padding: 0;
		vertical-align: top;
	}

	caption {
		font-weight: bold;
		text-align: left;
	}
}

.aspect-ratio-container-4-3 {
	width: 100%;
	padding-top: 75%;
}

.color-red {
	color: red;
}

.react-slidedown {
	.request-warning {
		display: none;
	}
}

.bg-input-changed {
	background-color: #BBF1DF !important;
}

.jodit-status-bar {
	display: none !important;
}

.jodit-container {
	min-width: 100% !important;
}

.custom-content {
	cursor: help;
	width: 100%;
}

.color-csk-primary {
	color: #004288;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-link {
	cursor: pointer;
}

.cursor-link:hover {
	text-decoration: underline;
}

.text-decoration-underline {
	text-decoration: underline;
}

.list-style-upper-roman {
	list-style-type: upper-roman;
}

.list-style-upper-roman li::before {
	content: none;
}

.list-style-upper-roman li,
div:first {
	padding-left: 15px;
}

.list-style-lower-alpha {
	list-style-type: lower-alpha;
}

.list-style-lower-alpha li::before {
	content: none;
}

.list-style-lower-alpha li,
div:first {
	padding-left: 15px;
}

.list-style-pl-15 {
	padding-left: 15px;
}

.badge-search {
	background: #ffee9b;
}

.span-as-hyperlink {
	cursor: pointer;
	color: #004288;
	transition: color 0.3s;
	-webkit-tap-highlight-color: transparent;
}

.span-as-hyperlink:hover {
	text-decoration: underline;
}

/* Responzivní column count */
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@for $i from 2 through $grid-columns {
			.column-count#{$infix}-#{$i} {
				column-count: $i;
				// orphans: 2;
				// widows: 3;
			}
		}

	}
}

.cookiealert.show {
	opacity: 1;
	visibility: visible;
	transform: translateY(0%);
	transition-delay: 1000ms;
}

.cookiealert {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0 !important;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	border-radius: 0;
	transform: translateY(100%);
	transition: all 500ms ease-out;
	color: #ecf0f1;
	background: rgba(0, 0, 0, 0.78);
	font-weight: bold;
}

.btn.acceptcookies.red {
	background-color: #d0103a;
	color: white;
}

.cookiealert .acceptcookies {
	margin-left: 0px;
	vertical-align: bottom;
}

.btn.acceptcookies,
.btn.disacceptcookies {
	background-color: #b1b1b1;
	max-width: 160px;
	height: 110px;
}

.cookies-alert-a {
	color: white;
	text-decoration: underline;
}

.input[type=date] {
	height: auto;
	padding-bottom: 18px;
}

.input[type=date].list__header-input {
	padding-bottom: 3px;
}

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted #004288;
}

.tooltip .tooltip-text {
	visibility: hidden;
	width: 520px;
	background-color: #004288;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
}

.tooltip:hover .tooltip-text {
	visibility: visible;
}

.menu-main--authorized .menu-main__link.--current {
	font-size: 12px;
}

.tooltip .tooltip-text::after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent black transparent transparent;
}

.html-expand-panel {
	cursor: pointer;
}

.html-expand-panel {}

.html-expand-panel-header {
	color: rgb(0, 0, 255);
}

.html-expand-panel-body.hidden {
	display: none
}

.login-text-mobile {
	display: none;
}

.login-text-desktop {
	display: block;

	@media ($smDown) {
		display: none;
	}
}


.login-text-mobile {
	display: none;

	@media ($smDown) {
		display: block;
	}
}

.menu-main__search {
	position: absolute;
	right: 30px;
	color: white;
	cursor: pointer;

	@media ($lgDown) {
		display: none;
	}
}

.document-list-folder {
	color: wheat;
}

.document-list-path {
	font-size: 14px;
	margin: 8px;
	margin-left: 0;
}

.document-list-path-item {
	display: inline;
	cursor: pointer;
}

.document-list-full-path {
	display: inline;
	position: absolute;
	margin-top: 5px;
}

.document-list-upload-no-background {
	background: none !important;
}

.no-background {
	background: none !important;
	padding: 0;
}

.documents-email-dialog {
	.k-editor {
		width: 100%;
	}
}

.folder-font {
	color: rgb(245, 222, 179);
}

.secondary-title {
	margin: 1.5rem 0 1rem;
	font-size: 1.125rem;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	font-weight: 400;
	line-height: 1.2;
	color: #004288;
}

.document-snem {
	@media (max-width: 999.98px) {
		.list__grid {

			td:nth-child(3),
			td:nth-child(4),
			th:nth-child(3),
			th:nth-child(4) {
				display: none;
			}
		}
	}
}

.remove-panel-radio {
	border: none !important;

	.checkbox-radio__title {
		margin-left: 5px !important;
	}
}