.about-us-become-member-page {
	&__text-read {
		color: red;
	}

	&__text-center {
		text-align: center;
	}
}

.about-us-list-bullets{
	list-style-type: disc;
	margin-left: 15px;
}

.about-us-list-bullets li::before{
	content: none;
}

.about-us-list-bullets li{
	padding-left: 0;
}

.about-us-member-page-i{
	font-style: italic;
	margin-top: 5px!important;
	font-size: 12px;
}
.about-us-csk-authorities-page {
	&__cards {
		display: flex;
		align-items: center;
		justify-content: center;

		@media ($lgDown) {
			flex-direction: column;
		}
	}

	&__cards-left {
		display: flex;
		flex-basis: 0;
		align-items: center;
		justify-content: center;

		@media ($mdDown) {
			flex-direction: column;
		}
	}

	&__cards-vertical {
		display: flex;
		flex-direction: column;
		flex-basis: 0;
	}

	&__cards-right {
		display: flex;
		justify-content: center;
		margin-left: 2rem;

		@media ($lgDown) {
			margin-top: 3rem;
			margin-left: 0;
		}

		.about-us-csk-authorities-page__card {
			width: 13rem;
			height: 13rem;

			@media ($lgDown) {
				width: 9rem;
				height: 9rem;
			}			
		}
	}

	&__card {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 17rem;
		height: 17rem;
		border-radius: 50%;
		background-color: $colorSecondary;
		cursor: pointer;

		@media ($lgDown) {
			width: 12rem;
			height: 12rem;
		}

		&:after {
			content: none;
		}

		&:hover {
			.about-us-csk-authorities-page__card-link::after {
				left: 0;
				right: 0;
				width: auto;
			}
		}

		&.--osk {
			&::before {
				content: "(";
				display: block;
				position: absolute;
				left: -20%;
				top: 40%;
				font-size: 4em;
				font-weight: 100;
				color: #00498e;
				line-height: 1px;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);

				@media ($mdDown) {
					display: none;
				}
			}

			.process-title {
				position: absolute;
				font-size: 0.9rem;
				top: 35%;
				left: -43%;

				@media ($lgDown) {
					top: 30%;
					left: -50%;
				}

				@media ($mdDown) {
					display: none;
				}
			}
		}

		&.--president {
			&::before {
				content: "(";
				display: block;
				position: absolute;
				left: -20%;
				bottom: 40%;
				font-size: 4em;
				font-weight: 100;
				line-height: 1px;
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);

				@media ($mdDown) {
					display: none;
				}
			}

			.process-title {
				position: absolute;
				font-size: 0.9rem;
				top: 59%;
				left: -41%;

				@media ($lgDown) {
					top: 61%;
					left: -47%;
				}

				@media ($mdDown) {
					display: none;
				}
			}
		}
	}

	&__card-title {
		font-size: 1.3rem;
		font-weight: 600;
		text-align: center;

		@media ($lgDown) {
			font-size: 1rem;
		}
	}

	&__card-link {
		position: absolute;
		bottom: 2rem;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			right: 50%;
			width: 0;
			bottom: 0.125rem;
			height: 0.0625rem;
			background-color: $colorPrimary;
			transition: left $t, right $t;
		}

		@media ($lgDown) {
			font-size: 0.8rem;
		}
	}
}
.about-us-page {
	&__motto {
		display: block;
		padding-top: 2rem;
		font-size: 3rem;
		font-style: normal;
	}

	&__activities {
		display: inline-block;
		text-align: left;
		line-height: 3rem;
		list-style: disc;

		> li {
			padding: 0;
			background-image: none;
		}
	}

	&__tile {
		background-color: #E8E8E8;
		padding: 1.5rem;
		margin-right: 2rem;
		margin-bottom: 2rem;
		width: 230px;

		&-number {
			font-size: 2.5rem;
			color: $colorPrimary;
		}

		&-text {
			color: black;
		}

		/* Odstranit podtržení */
		&::after {
			content: none;
		}
	}

	&__partner {
		position: relative;
		width: 170px;
		margin-right: 2rem;
		margin-bottom: 2rem;

		> img {
			width: 100%;
			height: 96px;
		}
		&-text {
			font-size: 0.9rem;
		}	

		/* Odstranit podtržení */
		&::after {
			content: none;
		}

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(255,255,255, 0.4);
			transition: background-color 0.3s;
		}

		&:hover {
			&::before {
				background-color: rgba(255,255,255, 0);
			}
		}
	}
}

.about-us-graphics-standards-title{
	font-size: 26px!important;
}

.about-us-graphics-standards-logo{
	height: 80px;
}
.agenda-koronavirus {
	margin-left: -16px;
	margin-top: -20px !important;
}
.books-cart-page {
	&__items {
		td {
			vertical-align: middle;
		}
	}

	&__item-image {
		width: 50px;
	}

	&__remove-icon {
		cursor: pointer;
		height: 2rem;
		path {
			fill: red;
		}
	}

	&__table-items {
		border: none;
		td {
			vertical-align: middle;
			border-right: none;
			border-left: none;
		}
	}

	&__table-sum {
		margin-left: auto;
		margin-right: 0;
		width: auto;

		th, td {
			font-weight: normal;
			vertical-align: top;
		}
	}

	&__cart-icon {
		height: 1em;
		path {
			fill: currentColor;
		}
	}
}
.books-checkout-page {
	&__table-items {
		min-width: 0;
	}
}
.books-item-page {
	&__image {
		width: 100%;
	}

	&__count {
		width: 7rem;
	}
}
.books-page {
	&__cart-icon {
		fill: currentColor;
		width: 2rem;
		height: auto;
	}

	&__button-cart {
		@media ($mdDown) {
			justify-content: center;
			width: 100%;
		}
	}
}
.contacts-csk-page-container {
	div {
		width: 100%
	}
}
.contacts-experts-page {
	&__card {
		height: 100%;
	}
}

.contacts-page-overflow {
	p {
		width: 250px;
		overflow: hidden;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.document-snem {
    @media (max-width: 999.98px) {
        .list__grid  {
            td:nth-child(3), td:nth-child(4),th:nth-child(3), th:nth-child(4) {
                display: none;
            }
        }
    }
}
.educational-action-description {
	margin: 0 0 0.15rem;
	text-align: justify;
}
.educational-actions-page {
	&__time-line-content {
		position: relative;
		padding-top: 1rem;
		top: -1rem;
		left: 0;
		transition: background-color 0.3s, left 0.3s;
		&:hover {
			background-color: #E8E8E8;
			left: 0.2rem;
		}	
	}
}
.emergencies-page {

	&__menu-item {

		cursor: pointer;
		border-right: 1px solid $colorSecondary;
		text-align: right;
		padding: 0.25rem 1.5rem 0.25rem 0.25rem;

		h2 {
			color: gray;
		}

		&:hover {
			border-right-color: $colorPrimary;

			h2 {
				color: $colorPrimary;
			}
		}

		&.--active {
			h2 {
				color: #004288 !important;
			}
		}
	}
}
.home-page {
	&__blue-panel {
		color: white;
		background-image: url("/images/dent-background.jpg");
		padding: 2.5rem 2.5rem 2.5rem 2.5rem;

		h1 {
			color: white;
			font-size: 3rem;
			border: none;
		}

		cite {
			display: inline-block;
			font-style: normal;
			margin-left: -2.5rem;
			background-color: darken($colorPrimary, 7);
			padding: 1.2rem;
		}
	}

	&__btn-main {
		height: 100%;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}

	&__tooth-icon {
		display: inline-block;
		width: 40px;
	}
}

.homepage-temporary {
	text-align: center;
	font-weight: bold;
	background: #fff0c5;
	padding: 25px;
	font-size: 24px;
}

.btn__text {
    padding: 1rem 1.875rem;
}
.my-documents-page {
	&__doc-icon {
		position: static;
	}
}
.my-educational-actions-btn {
	background-color: white;
	border-width: 1px;
}

.my-educational-dialog {
	width: 900px;
}

.my-educational-category-checkbox-radio {
	padding: 0 1.875rem 0.6rem 1.875rem;
	font-size: 0.875rem;
	line-height: 1.625;
	background-color: #dfeeff;
	color: #004288;
	font-size: 1rem;
	line-height: 1.5rem;
}
.news-page {
	&__time-line-content {
		position: relative;
		padding-top: 1rem;
		top: -1rem;
		left: 0;
		transition: background-color 0.3s, left 0.3s;
		&:hover {
			background-color: #E8E8E8;
			left: 0.2rem;
		}	
	}
}
.osk-documents-page {
	&__doc-icon {
		position: static;
	}
}

.osk-members-page {
	&__label-new {
		padding: 0.3rem;
		color: white;
		background-color: $colorPrimary;
		font-size: 12px;
		text-transform: uppercase;
	}
}
.page-404 {
	&__button-home {
		@include media-breakpoint-up(xl)
		{
			background: none;
			color: #fff;
			box-shadow: none;
			border:1px solid #fff;

			&:hover,
			&.active
			{
				background: white;
				color: #788891;
				box-shadow: 0px 4px 21px #F88342;
				text-decoration: none;
			}
		}
	}
}
.publication-page {
	&__card {
		height: 272px;
	}

	&__topic-number {
		width: 2.8rem;
		flex-shrink: 0;
		color: #004288;
		font-weight: 600;
	}
}

.publication-page-topic-color {
	color: #004288;
	font-weight: 600;
}

.publication-page-items-count {
	position: absolute;
	bottom: -77px;
	right: 20px;
	opacity: 0.5;
	z-index: 99;
	font-size: 200px;
	color: #cecece;
	font-weight: 600;
}

.publication-page-topic {
	min-height: 190px;
}

.publication-page-expand {
	z-index: 100;
	position: absolute;
	right: 22px;
	bottom: 5px;
}

.publication-topic-page-content-container {
	p {
		margin: 0 0 0.7rem;
	}
	blockquote {
		border-left:0px;
		padding: 0 0 0 0;
	}
	a {
		text-decoration: underline;
	}
}

.publication-page-arrow-top {
	position: sticky;
    bottom: 10px;
	padding: 3px 10px 7px 10px;
	background-color: #E8E8E8;
	color: #004288;
	cursor: pointer;
}

.publication-page-link-container {
	margin: 1.5rem 0 1rem;
}

.publication-page-link {
	font-size: 1.125rem;
	cursor: pointer;
	display: inline;
	font-weight: 400;
	line-height: 1.2;
	color: #004288;
}

.publication-page-link:hover {
	text-decoration: underline;
}
.web-request-fee-remission-page {
	&__table {
		border-collapse: collapse;
		td, th {
			border: 1px solid black;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}

		th {
			text-align: left;
		}
	}
}
.web-request-action-registration-page {
	&__action-name {
		font-size: 2rem;
	}
}
.web-request-action-registration-page {
	&__action-name {
		font-size: 2rem;
	}
}
.web-request-repeated-exam-in-vp {
	&__declaration {
		.checkbox-radio {
			align-items: flex-start;
		}
	}

	&__ordered-list {
		list-style-type: lower-alpha;
	}
}
.web-request-year {
	margin-bottom: 1.25rem;
}

.h-650 textarea {
	height: 500px !important;
}
.insurance-application-total {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	box-shadow: 0px 10px 18px -17px rgba(0, 0, 0, 0.75);
	transition: box-shadow 0.5s;
	background-color: lightgreen;
	padding-left: 5rem;
	padding-right: 5rem;
	padding: 20px;
}
.web-request-issue-certificate-after-education-page-check {
	background: none;
}
.web-request-osk-csk-entry-page {
	&__files {
		.file-upload {
			margin-bottom: 1rem;
		}
	}
}

.web-request-osk-csk-entry-page-text{
	font-size: 12px;
	float:left;
}
.workplace-inactive {
	background: #f3f3f3;
	padding: 15px;
	height: 100%;
	opacity: 0.6;
}

.workplace-inactive-list {
	td:has(.workplace-inactive) {
		padding: 0;
		height: 1px;
	}
}
.dialog {
	&__toolbar {
		margin-top: 3rem;
		padding: 0.5rem 1.2rem;
		color: white;
		background-color: rgb(252, 119, 3);
		width: calc(100% + 2 * #{$dialog-padding-x});
		margin-left: -$dialog-padding-x;
		margin-right: -$dialog-padding-x;
		margin-bottom: -$dialog-padding-y;

		button:hover {
			color: darken(white, 10%);
		}

		@include media-breakpoint-down(xs) {
			width: calc(100% + 2 * #{$dialog-padding-x-xs});
			margin-left: -$dialog-padding-x-xs;
			margin-right: -$dialog-padding-x-xs;
		}
	}

	&--limited-width {
		max-width: 600px;
	}
}

.dialog-min-width-900 {
	min-width: 900px;
}
.books-list {
	&__cards {
		margin-left: -26px;
		margin-right: -26px;
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 1rem;
		cursor: pointer;
		height: 100%;

		&:hover {
			.books-page__item-image	{
				opacity: 0.6;
			}
		}
	}

	&__item-image {
		width: 100%;
		transition: opacity 0.8s;
	}

	&__item-title {
		text-transform: uppercase;
		font-weight: bold;
		color: $colorPrimary;
		text-align: left;
	}

	&__item-button-add {
		padding: 0.5rem;
	}
}
.action-list {
	&__button {
		margin-right: 0.5em;
		&:last-child {
			margin-right: 0;	
		}
	}
}
.file-upload {
	display: flex;
	align-items: stretch;
	flex-wrap: nowrap;
	margin-bottom: 2.2rem;

	&__button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&__spinner {
		width: 27px;
		height: 27px;
	}
}

.input.file-upload__input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none;	
	margin-right: 0;
	margin-bottom: 0;
}
.list {
	&__header {
		display: flex;
		align-items: center;
		height: 0.5rem;
		position: relative;
		top: -0.5rem;
		font-weight: bold;
	}

	&__item-row {
		padding: 1em 0 1em 0;
		border-bottom: 1px solid lighten(lightgrey, 15%);
	}

	&__content {
		border-top: 1px solid lightgrey;	
	}

	&__search-input {
		background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2 13.8L19.7 18.3C20.1 18.7 20.1 19.3 19.7 19.7C19.5 19.9 19.2 20 19 20C18.8 20 18.5 19.9 18.3 19.7L13.8 15.2C12.3 16.3 10.5 17 8.5 17C3.8 17 0 13.2 0 8.5C0 3.8 3.8 0 8.5 0C13.2 0 17 3.8 17 8.5C17 10.5 16.3 12.4 15.2 13.8ZM8.5 2C4.9 2 2 4.9 2 8.5C2 12.1 4.9 15 8.5 15C10.3 15 11.9 14.3 13.1 13.1C14.3 11.9 15 10.3 15 8.5C15 4.9 12.1 2 8.5 2Z" fill="#{$form-item-label-color}"/></svg>');
		background-repeat: no-repeat;
		background-size: 1em;
		background-position: center left 0.5em;
		padding-left: 2em;
		margin-bottom: 0.1em;
		max-width: 30em;
	}

	&__grid-wrapper{
		min-height: 100px;
	}

	&__grid-container {
		width: 100%;
		overflow-x: auto;
		min-height: 150px;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	&__grid {
		width: 100%;
		max-width: 100%;	
		table-layout: fixed;

		td {
			overflow: hidden;
		}
	}

	&__grid-column-header {
		cursor: pointer;
	}

	&__grid-column-header-dropdown {
		.drop-down-menu__trigger {
			text-transform: uppercase;
			font-weight: lighter;
			font-size: 12px;
			padding: 10px;
			margin-left: -10px;
		}	

		.dropdown__content {
			padding: 0.8rem 0;
			top: 1.4em;
			left: -10px;
			min-width: 180px;
		}

		.dropdown.dropdown--open {
			.drop-down-menu__trigger {
				background-color: white;
			}
		}
	}

	&__header-input {
		background-color: white;
		padding: 0.2em 0.5em;

		&[type="date"] {
			/* 
			* Kvůli chybě iOS Safari, kde prázdný input je scvrknutý na výšku
			* Výpočet (font-size * line-height + padding + border)
			*/
			height: calc((0.875rem * 1.625) + 0.4rem);
		}
	}
}
.list-filter-info {
	color: darken($color-text, 20%);
	background-color: lighten(orange, 40%);
	padding: 0.5em 0.5em 0.5em 1em;
	border-radius: 2em;

	&__cancel-button {
		background-color: orange;
		color: white;
		border-radius: 2em;
		padding: 0.2em 1em;

		&:hover {
			background-color: lighten(orange, 10%);
		}
	}
}
.list-sorting {

	font-size: 0.75rem;

	&__order-button {
		@include input;

		border-right-width: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: auto;

		&:focus {
			background-color: $input-bg;
		}
	}

	&__select {
		border-left-width: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		width: auto;

		&:focus {
			background-color: $input-bg;
		}
	}

	.dropdown__content {
		top: 24px;
	}
}
.list-tab-filter {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: -40px;
	margin-left: -15px;

	&__states {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		font-size: 0.8rem;
		text-transform: uppercase;
		padding: 1rem 15px;
		border-bottom: solid 3px white;
		cursor: pointer;

		&:hover {
			color: orange;
			border-bottom: solid 3px orange;
		}

		&--active {
			color: orange;
			border-bottom: solid 3px orange;	
		}

		&--deleted {
			margin-left: 3em;
			white-space: nowrap;
		}
	}

	&__line {
		height: 1px;
		background-color: #e0e3ea;
		margin-left: -40px;
		margin-right: -40px;
	}
}
.query-tags-fields-toolbar-button {
	padding: 2px;
	margin: 2px;
	text-align: center;
	cursor: pointer;
}

.query-tags-fields-toggler {
	cursor: pointer;
	width: 125px;
}