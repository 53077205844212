.accessibility {
	position: absolute;
	top: 0;
	left: -312.5rem;
	a {
		&:focus,
		&:active {
			position: absolute;
			top: 0;
			left: 312.5rem;
			z-index: 900;
			width: 12.5rem;
			padding: 0.125rem 0 0.3125rem;
			background-color: $colorWhite;
			text-align: center;
		}
	}
}

.autocomplete {
	position: relative;

	&__dropdown {
		position: absolute;
		top: 66px;
		left: 0px;
		background-color: $input-bg;
		box-shadow: .3125rem 0.625rem 1.25rem rgba(33, 37, 41, 0.25);
		max-height: 400px;
		transition: max-height 0.5s;
		overflow: auto;
		z-index: 1;
		box-sizing: border-box;

		&--hidden {
			max-height: 0;
			transition: max-height 0.5s;
		}
	}

	&__dropdown-item {
		font-size: rem(18);
		color: $colorPrimary;
		padding: 0.3rem 1.875rem;
		cursor: default;

		&--selected {
			color: white;
			background-color: $colorPrimary;
		}
	}
}
.button {
	font-size: 1rem;
	display: inline-flex;
	align-items: center;
	padding: 1.25rem 1.875rem;
	line-height: 1.5;
	cursor: pointer;
	transition: background-color $t, border-color $t;

	&:hover {
		text-decoration: none;
	}

	&:hover,
	&:focus {
		outline: none !important;
	}

	&__spinner-container {
		display: inline-flex;
		align-items: center;
		height: 0;
		overflow: visible;
		margin-right: 0.5rem;
	}
}

@mixin button-theme($bg-color, $color, $outline: false) {
	border: 1px solid $bg-color;
	cursor: pointer;

	@if $outline {
		color: $bg-color;
		background-color: transparent;
		border-color: $bg-color;
	}

	@else {
		color: $color;
		background-color: $bg-color;
	}
}

.button-primary {
	@include button-theme($colorPrimary, white);

	&:hover {
		color: $colorPrimary;
		background-color: $colorTertiary;
		border-color: $colorTertiary;
	}

	background-color: #004288;
}

.button-secondary {
	@include button-theme($colorSecondary, $colorPrimary);

	&:hover {
		background-color: $colorTertiary;
		border-color: $colorTertiary;
	}
}
.card-expandable {
	&__title {
		cursor: pointer;
		margin-top: -16px;
		margin-bottom: -16px;
		padding-top: 16px;
		padding-bottom: 16px;

		&:first-child {
			margin-top: -16px;	
		}
	}
	&__expand-icon {
		width: 15px;
		height: 15px;
		margin-left: 30px;

		color: $colorPrimary;
	}
}
.card-link {
	&:hover {
		&::after {
			content: none;
		}
	}

	&__arrow {
		width: 15px;
		height: 15px;
	}

	&__card {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.checkbox-radio {
	@include input;
	margin-bottom: $gutter;
	display: flex;
	align-items: center;
	cursor: pointer;

	&__checkmark {
		display: inline-block;
		height: 22px;
		width: 22px;
		background-color: white;
		border: 1px solid $colorPrimary;
		box-sizing: border-box;
		cursor: pointer;
		flex-shrink: 0;

		&:after {
			content: "";
			position: relative;
			left: 4px;
			top: 4px;
			background-color: $colorPrimary;
			width: 12px;
			height: 12px;
			border: solid $colorPrimary;
			border-width: 3px 3px 3px 3px;
			display: none;
		}
	}

	&__input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked ~ .checkbox-radio__checkmark {
			background-color: white;

			&:after {
				display: block;
			}
		}
	}

	&__title {
		margin-left: 15px;
	}

	&--radio {
		.checkbox-radio__checkmark {
			border-radius: 50%;
			&:after {
				border-radius: 50%;
			}
		}
	}

	&--small {
		.checkbox-radio__checkmark {
			height: 16px;
			width: 16px;
			border-radius: 4px;

			&:after {
				content: "";
				position: relative;
				left: 5px;
				top: 2px;
				width: 6px;
				height: 10px;
				border: solid white;
				border-width: 0 3px 3px 0;
				transform: rotate(50deg);
				display: none;
			}
		}

		.checkbox-radio__title {
			font-size: 14px;
		}
	}

	&--readonly {
		background-color: $colorTertiary;
		cursor: default;

		.checkbox-radio__checkmark {
			border-color: lightgray;
			cursor: default;	

			&:after {
				background-color: lightgray;
				border-color: lightgray;
			}
		}
	}
}
.checkbox-radio-frame {
	background-color: $input-bg;
	border: 1px solid transparent;
	padding: 1rem 0;
	transition: background-color 0.5s, border-color 0.5s;

	.form-item {
		margin-bottom: 0;
	}

	.checkbox-radio {
		margin-bottom: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-color: transparent;
		background-color: transparent;
	}

	&--readonly {
		background-color: $colorTertiary;
	}

	&.--invalid {
		background-color: #fCf4f2;
		border: solid 1px $colorRed;
	}
}
.box-contact-small {
	ul {
		@extend %reset-ul;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		margin-left: -0.625rem;
	}
	li {
		@extend %reset-ul-li;
		flex: 0 1 auto;
		padding-top: 0.125rem;
		border-left: 0.625rem solid transparent;
	}

	// States
	li a {
		.hoverevents &:hover {
			color: $colorLink;
			text-decoration: none;
		}
	}

	// Media
	@media (max-width: 1150px) {
		li {
			margin-bottom: 0.0625rem;
			padding-top: 0.375rem;
			font-size: 0.75rem;
		}
	}
}

.dropdown {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border-radius: 0.3rem;

	> .dropdown__content {
		position: absolute;
		margin-top: 1px;
		left: 0;
		background-color: white;
		min-width: 220px;
		box-shadow: .3125rem 0.625rem 1.25rem rgba(33, 37, 41, 0.25);
		z-index: 1;
	}

	> .dropdown__trigger {
		position: relative;
		z-index: 2;
	}

	&--open {
		background-color: #e6e6e6;
	}

	&__separator {
		height: 1px;
		border-top: 1px solid #e6e6e6;
	}

	&__content {
		max-height: calc(100vh - 8rem);
		overflow-y: scroll;
	}

	&--nested {
		.dropdown__content {
			max-height: none;
			overflow-y: visible;
		}
	}
}

.drop-down-menu {
	&__item {
		display: block;
		padding: 0.3rem 1rem;
		color: inherit;
		text-decoration: none;
		white-space: nowrap;
		font-weight: normal;
		
		&:hover {
			background-color: #e6e6e6;
			color: inherit;
			text-decoration: none;
		}
	}
}
.form-item {
	position: relative;
	margin-bottom: 1rem;

	>input,
	textarea,
	select {
		display: block;
	}

	&__label {
		width: 100%;
		font-size: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		// position: absolute;
		// top: -1.6rem;
		margin-bottom: 0;
	}

	&__required {
		font-weight: bold;
		color: red;
	}

	&__hint-icon {
		path {
			fill: $colorPrimary;
		}
	}

	&__tooltip {
		>* {
			width: 100%;
		}
	}

	&__hint-tooltip {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		flex-shrink: 0;
	}

	&__hint-icon {
		width: 20px;
		cursor: pointer;
	}

	&--invalid {

		input,
		textarea,
		label.checkbox-radio,
		select {
			border: solid 1px $colorRed;
			background-color: $colorRedLight;
			transition: background-color 0.5s;

			&:focus {
				border: solid 1px $colorRed;
			}
		}

		.checkbox-radio-frame {
			background-color: $colorRedLight;
			border: solid 1px $colorRed;

			label.checkbox-radio {
				border-color: transparent;
				background-color: transparent;
			}
		}
	}
}

.hide-numeric {

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
}
.form-text {
	padding: 1.25rem 0;
	margin-bottom: 2.2rem;
}
.html-editor.k-editor {

	.k-toolbar .k-dropdown .k-dropdown-wrap .k-input {
		border-radius: 0.25rem 0 0 0.25rem;
	}

	.k-toolbar .k-dropdown .k-dropdown-wrap .k-input,
	.k-toolbar .k-dropdown .k-dropdown-wrap .k-select {
		padding: 0.3rem 1rem;
		height: auto;
		color: white;
		background-color: $secondary;
	}

	.k-editable-area.ProseMirror-focused,
	.k-editable-area:focus {
		box-shadow: none;
	}
}

.html-content-error-message{
	//color: #dc3545!important;
}
.input {
	@include input;
}
.label {
	padding: 0.3rem;
	color: white;
	background-color: $colorPrimary;
	font-size: 12px;
	text-transform: uppercase;
}
.link {
	&.--no-style {
		color: $color-text;

		&::after {
			content: none;
		}	
	}
}
.mapy-cz-map {
	width: 100%;
	padding-top: 75%;
	img {
		max-width: none;
	}
}
.menu-division {
	&__burger {
		position: relative;
		display: none;
	}

	&__list {
		@extend %reset-ul;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;

		&.react-slidedown.closed {
			display: flex;	
		}
	}

	&__item {
		@extend %reset-ul-li;
		flex: 0 1 auto;
		a {
			display: block;
			padding: 0.3125rem 0.625rem;
			background-color: $colorSecondary;
			text-decoration: none;
			transition: background $t;
			&::after {
				display: none;
			}
		}

		&:first-child {
			border-left: 0;
		}
	}

	// Variants
	&--division &__item a {
		padding: 0.3125rem 3.125rem;
	}

	&--division &__item--division a {
		padding: 0.3125rem 0.625rem;
		background-color: $colorSecondary;
		color: $colorPrimary;
	}

	&--division &__item--division.--active a {
		color: $colorSecondary;
		background-color: $colorPrimary;
	}

	// States
	&__item a {
		.hoverevents &:hover {
			background-color: $colorTertiary;
			color: $colorLink;
		}
	}

	// Media
	// @media ($lgDown) {
	// 	&__item:not(.menu-division__item--division) a {
	// 		position: relative;
	// 		width: 2.125rem;
	// 		height: 2.125rem;
	// 		padding: 0;
	// 		font-size: 0;
	// 	}
	// 	&--division &__item--division a {
	// 		padding: 0.5rem;
	// 		font-size: 0.75rem;
	// 	}
	// }

	@media ($lgDown) {
		position: relative;

		&__burger {
			display: inline;
		}

		&__list {
			position: absolute;
			z-index: 200;
			top: 2.1875rem;
			left: 0;
			width: 140px;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-end;
			background-color: $colorSecondary;
			box-shadow: 0.3125rem 0.625rem 1.25rem rgba($colorBlack, 0.25);

			&.react-slidedown.closed {
				display: none;
			}
		}	
		&__item {
			position: relative;
			flex: 0 1 100%;
			border-bottom: 1px solid $colorTertiary;
			border-left: none;
			text-align: left;
			width: 100%;
			padding: 0.3125rem 0;

			&:first-child {
				padding-top: 1rem;
			}

			&:last-child {
				padding-bottom: 1rem;
				border-bottom: none;
			}
		}
		
		&--division &__item a {
			padding: 0.3125rem 0.5rem;
		}

		&__item a {
			font-size: 0.75rem;
			color: $colorPrimary;

			position: relative;
			display: block;
		}

		// States
		.hoverevents &__item a {
			background-color: $colorSecondary !important;
			color: $colorPrimary !important;
		}
	}
}

.menu-footer {
	&__inner {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: flex-start;
		margin-left: -3.75rem;
		margin-bottom: -1.25rem;
	}
	&__block {
		flex: 0 1 auto;
		border-left: 3.75rem solid transparent;
		border-bottom: 1.25rem solid transparent;
	}
	&__list {
		@extend %reset-ul;
		&.react-slidedown.closed {
			display: block;	
		}
	}
	&__item {
		@extend %reset-ul-li;
		position: relative;
		padding: 0.3125rem 0.3125rem 0.3125rem 1.25rem;
		font-size: 0.875rem;
		&::before {
			content: "-";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			font-weight: $semiBold;
			color: $colorPrimary;
		}
	}
	&__link {
		display: block;
	}

	// Media
	@media ($lgDown) {
		&__block {
			flex: 0 1 33.33%;
		}
	}

	@media ($mdDown) {
		&__inner {
			flex-wrap: wrap;
		}
		&__block {
			flex: 0 1 100%;
			border-bottom: none;
			&:last-child {
				border-bottom: 1.25rem solid transparent;
			}
		}
		&__title {
			position: relative;
			padding: 0.625rem 2.5rem 0.625rem 1.25rem;
			background-color: $colorTertiary;
			cursor: pointer;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: 1.25rem;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid $colorPrimary;
			}

			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			&:first-child {
				margin-top: 0.5rem;	
			}
		}
		&__list {
			&.react-slidedown.closed {
				display: none;	
			}
		}
	}
}

.menu-list {
	margin: 0;
	padding: 0.5rem;
	background-color: $color-secondary;

	> li {
		background-image: none;
		margin: 0;
		padding: 0;
		font-size: 0.8rem;
		color: $colorPrimary;

		> * {
			display: block;
			padding: 0.5rem 1rem;
			margin: 0 -0.5rem;

			&:hover {
				background-color: $colorTertiary;
			}
		}

		> .separated {
			border-top: 1px solid #F5F1ED;
		}
	}
}
.menu-main {
	background-color: $colorPrimary;

	&__burger {
		position: relative;
		display: none;
		color: $colorWhite;
		cursor: pointer;

		.icon-svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0.875rem;
		}
	}

	&__list {
		@extend %reset-ul;
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		// justify-content: space-between;

		&.react-slidedown.closed {
			display: flex;
		}
	}

	&__item {
		@extend %reset-ul-li;
		position: relative;

		&:last-child {
			padding-right: 4.625rem;
		}
	}

	&__link {
		display: block;
		font-size: 12px;
		padding: 1rem 0.75rem 0.875rem;
		color: $colorWhite;
		text-transform: uppercase;
		text-align: center;
		text-decoration: none;
		transition: background $t;

		&::after {
			display: none;
		}

		&.--current {
			background-color: $colorSecondary;
			color: $colorPrimary;
		}
	}

	&__opener {
		display: none;

		.icon-svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 0.625rem;
			margin-top: -1px;
		}
	}

	&__sublist {
		@extend %reset-ul;
		position: absolute;
		overflow: hidden;
		z-index: 100;
		left: 0;
		min-width: 10rem;
		visibility: hidden;
		opacity: 0;
		background-color: $colorSecondary;
		transition: visibility $t, opacity $t;

		&.react-slidedown.closed {
			display: block;
		}
	}

	&__subitem {
		@extend %reset-ul-li;
		white-space: nowrap;
		padding-left: 0;
		width: 100%;
		text-align: left;
	}

	&__sublink {
		display: block;
		font-size: 0.75rem;
		color: $colorText;
		text-transform: uppercase;
		text-decoration: none;
		transition: background $t;

		&::after {
			display: none;
		}
	}

	&__sublink-title {
		display: block;
		padding: 0.425rem 1rem;
	}

	// States
	&__item:hover &__sublist {
		visibility: visible;
		opacity: 1;

		@media ($lgUp) {
			height: auto;
		}
	}

	&__item:hover &__link {
		background-color: $colorSecondary;
		color: $colorLink;
	}

	&__link {
		&:hover {
			background-color: $colorSecondary;
			color: $colorLink;
		}
	}

	&__opener {
		&.is-open {
			.icon-svg--minus {
				display: block;
			}

			.icon-svg--plus {
				display: none;
			}
		}
	}

	&__sublist {
		&.is-open {
			visibility: visible;
			opacity: 1;
		}
	}

	&__sublink {
		&:hover {
			background-color: $colorTertiary;
			color: $colorText;
		}
	}

	// Media
	@media (max-width: 1250px) {
		&__link {
			padding: 1.125rem 0.625rem 0.9375rem 0.625rem;
			font-size: 0.875rem;
		}
	}

	@media (max-width: 1150px) {
		&__link {
			padding: 1.125rem 0.625rem 1.125rem 0.625rem;
			font-size: 0.75rem;
		}
	}

	@media ($lgDown) {
		position: relative;
		display: inline-block;
		margin-bottom: 0 !important;

		&__burger {
			display: block;
			width: 2.125rem;
			height: 2.125rem;
		}

		&__list {
			position: absolute;
			z-index: 100;
			top: 2.1875rem;
			right: 0;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-end;
			width: 15rem;
			background-color: $colorPrimary;
			box-shadow: 0.3125rem 0.625rem 1.25rem rgba($colorBlack, 0.25);
		}

		&__list.react-slidedown.closed {
			display: none;
		}

		&__item {
			position: relative;
			flex: 0 1 100%;
			border-bottom: 1px solid lighten($colorPrimary, 10%);

			&:last-child {
				padding-right: 0;
				border-bottom: none;
			}
		}

		&__link {
			text-align: right;
			padding: 0.625rem 0.625rem 0.625rem 2.375rem;

			&.--current {
				background-color: $colorPrimary;
				color: $colorSecondary;
			}
		}

		&__opener {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 2.375rem;
			height: 2.375rem;
			color: $colorSecondary;
			cursor: pointer;
		}

		&__sublist {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			visibility: visible;
			opacity: 1;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-end;
			background-color: $colorPrimary;

			&.react-slidedown.closed {
				display: none;
			}
		}

		&__subitem {
			flex: 0 1 100%;
			border-bottom: 1px solid lighten($colorPrimary, 10%);

			&:last-child {
				padding-right: 0;
				border-bottom: none;
			}
		}

		&__sublink {
			height: auto;
			color: $colorSecondary;
			text-align: right;
		}

		&__sublink-title {
			padding: 0.625rem 1rem;
		}

		// States
		&__item:hover &__link {
			background-color: $colorPrimary;
			color: $colorWhite;
		}

		&__link {
			&:hover {
				background-color: $colorPrimary;
			}
		}

		&__sublink {
			&:hover {
				background-color: $colorPrimary;
				color: $colorSecondary;
			}
		}
	}

	&--authorized {
		font-size: 0.8rem;
		background-color: $colorSecondary;

		.menu-main__item {
			@media ($lgDown) {
				border-bottom-color: lighten($colorSecondary, 5);
			}
		}

		.menu-main__subitem {
			@media ($lgDown) {
				border-bottom-color: lighten($colorSecondary, 5);
			}
		}

		.menu-main__burger {
			.icon-svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 0.875rem;
			}
		}

		.menu-main__list {
			background-color: $colorSecondary;
		}

		.menu-main__link {
			color: $colorPrimary;
			background-color: transparent;

			&.--current {
				background-color: $colorPrimary;
				color: $colorSecondary;
			}

			@media ($lgDown) {
				&.--current {
					background-color: $colorSecondary;
					color: $colorPrimary;
				}
			}
		}

		.menu-main__sublist {
			color: white;
			background-color: $colorPrimary;
		}

		.menu-main__sublink {
			color: white;

			&:hover {
				@media ($lgUp) {
					color: $colorPrimary;
				}
			}
		}

		.menu-main__sublist {
			@media ($lgDown) {
				background-color: $colorSecondary;
			}
		}

		.menu-main__opener {
			color: $colorPrimary;
		}

		.menu-main__item:hover {

			@media ($lgUp) {
				background-color: $colorPrimary;
				color: white;
			}

			.menu-main__link {
				background-color: $colorSecondary;
				color: $colorPrimary;

				@media ($lgUp) {
					background-color: $colorPrimary;
					color: white;
				}
			}
		}

		.menu-main__subitem {
			@media ($lgDown) {
				background-color: $colorSecondary;
				color: $colorPrimary;
			}

			.menu-main__sublink {
				@media ($lgDown) {
					background-color: $colorSecondary;
					color: $colorPrimary;
				}
			}
		}
	}

}

.photo-gallery-image {
	cursor: pointer;
}
.custom-select {
	@include input;

	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;

	background-image: url("data:image/svg+xml;utf8,<svg fill='%237ea3b8' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: calc(100% - 5px);
	background-position-y: 16px;
	padding-right: 40px;
}
.react-slidedown {
    transition-property: height;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}
.textarea
{
	@include input;
	min-height: 200px;
}
.tile {
	background-color: #E8E8E8;
	min-width: 200px;
	padding: 1.5rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	text-align: center;

	&__number {
		font-size: 2.5rem;
		color: $colorPrimary;
	}

	&__text {
		color: black;
	}

	&--emphasiz {
		.tile__number	{
			color: red;
		}
	}

	/* Odstranit podtržení */
	&::after {
		content: none;
	}
}
.time-line {
	display: flex;
	justify-content: space-between;

	&__line {
		width: 5px;
		min-width: 5px;
		margin-left: 150px;
		margin-right: 3rem;
		background-color: $colorPrimary;	

		@media ($mdDown) {
			display: none;
		}
	}

	&__bb {
		border-bottom: solid 1px #E8E8E8;
	}

	&__mark {
		position: relative;
		text-align: center;
		width: 140px;
		float: left;
		font-size: 0.9rem;
		font-weight: bolder;
		margin-left: calc(-3rem - 5px - 150px);
		overflow: visible;
		background-color: #E8E8E8;
		padding: 0.5rem 0.5rem;
	}
}
.login-form {
	text-align: center;
	
	a {
		text-decoration: none;
	}
}
.footer {
	background-color: $colorSecondary;
	&__inner {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: space-between;
		margin-left: -3.75rem;
		margin-bottom: -1.25rem;
	}
	&__item {
		flex: 1 1 auto;
		border-left: 3.75rem solid transparent;
		border-bottom: 1.25rem solid transparent;
		&:first-child {
			padding-top: 4.25rem;
		}
		&:last-child {
			flex: 0 1 21.875rem;
		}
	}

	// Media
	@media ($lgDown) {
		&__inner {
			flex-wrap: wrap;
		}
		&__item {
			&:first-child {
				padding-top: 0;
			}
		}
	}
}

.header {
	padding-top: 1.875rem;

	&__inner {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: space-between;
	}

	&__item {
		flex: 0 1 auto;
		display: block;
		margin-bottom: 0.9375rem;
	}

	&__top {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 0.4375rem;

		a:after {
			content: none;
		}

		.button {
			padding: 0.3125rem 0.625rem;
			line-height: 1.5;
			border: none;
		}
	}

	&__bottom {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 0.4375rem;

		.button {
			padding: 0.3125rem 0.625rem;
			line-height: 1.5;
			border: none;
		}
	}

	&__top-item {
		flex: 0 1 auto;
		border-left: 0.625rem solid transparent;

		&:first-child {
			border-left: none;
		}
	}

	&__icon-user {
		width: 14px;
	}

	// Media
	@media (max-width: 1150px) {
		&__item {
			margin-bottom: 1rem;
		}
	}

	@media ($lgDown) {
		&__bottom {
			min-height: 2.125rem;
		}

		&__item {
			margin-bottom: 0;
		}
	}

	&__menu-public {
		&.--authorized {
			.menu-main__list {
				@media ($lgDown) {
					right: 0px;
				}
			}
		}
	}

	&__menu-authorized {
		@media ($lgDown) {
			margin-left: 1px;
		}
	}
}

.login-text-mobile {
	display: none;
}

.login-text-desktop {
	display: block;

	@media ($smDown) {
		display: none;
	}
}


.login-text-mobile {
	display: none;

	@media ($smDown) {
		display: block;
	}
}
.page {
	position: relative;

	&--modal-open {
		// -webkit-filter: blur(1px);
		// -moz-filter: blur(1px);
		// -o-filter: blur(1px);
		// -ms-filter: blur(1px);
		// filter: blur(1px);
	}
}

.main {
	padding: $gutter 0;
}
.publication-article-template {
	&__topics {
		background-color: $colorSecondary;
	}

	&__topic-number {
		width: 2.8rem;
		flex-shrink: 0;
	}
}
.web-request {
	&__header {
		border: 0.0625rem solid #E8E8E8;
		background-color: rgba(0, 66, 136, 0.05);
		padding: 1.5rem;
	}

	&__header-status {
		border: 0.0625rem solid #E8E8E8;
		background-color: #fCf4f2;
		padding: 1.5rem;
	}

	&__form-switch {
		cursor: pointer;
	}
}