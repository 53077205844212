.menu-signpost {
	height: 100%;
	ul {
		@extend %reset-ul;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		height: 100%;
		margin-bottom: -1.25rem;
	}
	li {
		@extend %reset-ul-li;
		flex: 1 1 auto;
		border-bottom: 1.25rem solid transparent;
		a {
			position: relative;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;
			height: 100%;
			padding: 1.25rem;
			background-color: $colorSecondary;
			text-transform: uppercase;
			text-decoration: none;
			transition: background $t;
			> * {
				flex: 0 1 auto;
			}
		}
		&:first-child a {
			padding-left: 5.625rem;
			font-size: 1.25rem;
			font-weight: $medium;
		}
		&:last-child {
			border-bottom: none;
		}
	}

	// States
	a {
		.hoverevents &:hover {
			background-color: $colorTertiary;
			color: $colorLink;
		}
	}

}
