@use "sass:map";

// Colors
$colorSecondary: #E8E8E8;
$colorTertiary: #F5F1ED;

$colorBlack: #212529;
$colorWhite: #ffffff;
$colorRed: #e62600;
$colorRedLight: #fCf4f2;
$colorGreen: #77B259;
$colorGreenLight: #f3faef;
$colorOrange: #e09600;
$colorOrangeLight: #fdf8ed;

$colorText: $colorBlack;
$colorBd: $colorSecondary;
$colorBg: $colorSecondary;
$colorLink: $colorPrimary;
$colorHover: $colorSecondary;

$colorFacebook: #3b5998;
$colorTwitter: #1da1f2;
$colorLinkedIn: #0077b5;

// Font
$font: 'Montserrat', Arial, Helvetica, sans-serif;
$fontPrimary: $font;
$fontSecondary: $font;
$fontSize: 1rem;
$lineHeight: (24 / 16);

// Font weight
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

// Typography
$spaceVertical: 1.25rem;

// Grid
$gridColumns: 12;
$gutter: 1.25rem;
$rowWidth: 80rem;

// Transitions
$t: 0.3s;

// SVGs
$svgBullet: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath d='M0 0h4v4H0z'/%3E%3C/svg%3E%0A";
$svgSelect: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath d='M10 0L5 5 0 0'/%3E%3C/svg%3E%0A";

// specificky dělané breakpointy
$breakpoints: (
	sm: 480px,
	md: 750px,
	lg: 1000px,
	xl: 1200px
);

$breakpointsVars: (
	smUp: "min-width: 480px",
	smDown: "max-width: 479.98px",
	mdUp: "min-width: 750px",
	mdDown: "max-width: 749.98px",
	lgUp: "min-width: 1000px",
	lgDown: "max-width: 999.98px",
	xlUp: "min-width: 1200px",
	xlDown: "max-width: 1199.98px"
);

$smUp: map-get($breakpointsVars, smUp);
$smDown: map-get($breakpointsVars, smDown);
$mdUp: map-get($breakpointsVars, mdUp);
$mdDown: map-get($breakpointsVars, mdDown);
$lgUp: map-get($breakpointsVars, lgUp);
$lgDown: map-get($breakpointsVars, lgDown);
$xlUp: map-get($breakpointsVars, xlUp);
$xlDown: map-get($breakpointsVars, xlDown);
