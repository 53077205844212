.btn {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	border: 0;
	background: none;
	text-decoration: none;
	cursor: pointer;
	&::after {
		display: none;
	}
	&__text {
		position: relative;
		display: block;
		padding: 1.25rem 1.875rem;
		border: 0.0625rem solid transparent;
		background-color: $colorLink;
		color: $colorWhite;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: center;
		text-decoration: none;
		transition: background $t, border $t, color $t;
	}
	&__tooltip {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: -6.875rem;
		right: 0;
		width: 17.5;
		padding: 1.25rem;
		background-color: $colorTertiary;
		box-shadow: 0.3125rem 0.625rem 1.25rem rgba($colorBlack, 0.25);
		border-radius: 0.3125rem;
		font-family: $font;
		font-size: 0.75rem;
		text-align: left;
		transition: visibility $t, opacity $t;
		&::after {
			content: "";
			position: absolute;
			right: 1.25rem;
			bottom: -0.625rem;
			width: 0;
			height: 0;
			border-left: 0.625rem solid transparent;
			border-right: 0.625rem solid transparent;
			border-top: 0.625rem solid $colorTertiary;
		}
	}

	// Variants
	&--light,
	&--light &__text {
		background-color: $colorSecondary;
		color: $colorPrimary;
	}
	&--tooltip {
		position: relative;
	}
	&__tooltip {
		&.is-active {
			visibility: visible;
			opacity: 1;
		}
	}
	&--icon-left &__text {
		position: relative;
		padding-left: 4.625rem;
		.icon-svg {
			position: absolute;
			top: 50%;
			left: 1.875rem;
			transform: translateY(-50%);
		}
	}
	&--icon-right &__text {
		position: relative;
		padding-right: 4.625rem;
	}
	&--filter &__text {
		padding: 0.625rem 1.875rem;
	}
	&--search {
		flex: 0 0 3.375rem;
	}
	&--search &__text {
		position: relative;
		width: 3.375rem;
		height: 3.375rem;
		padding: 0;
		background-color: $colorPrimary;
		color: $colorWhite;
	}
	&--dentists {
		margin-bottom: 0.625rem;
	}
	&--dentists &__text {
		padding: 0.625rem 1.875rem;
		font-size: 0.875rem;
	}

	// States
	&:disabled,
	&.is-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	.hoverevents &:hover &__text {
		background-color: $colorHover;
		color: $colorPrimary;
	}
	.hoverevents &--light:hover &__text {
		background-color: $colorTertiary;
	}
	.hoverevents &--filter:hover &__text {
		background-color: $colorTertiary;
	}
	.hoverevents &--search:hover &__text {
		background-color: $colorTertiary;
	}
	.hoverevents &--footer:hover &__text {
		background-color: $colorTertiary;
	}

	// Media
	@media ($lgDown) {
		&--search {
			flex: 0 0 2.125rem;
		}
		&--search &__text {
			width: 2.125rem;
			height: 2.125rem;
			line-height: 2.125rem;
		}
	}
}

.js-file {
	width: 100%;
	> span {
		display: block;
		margin-bottom: 1.25rem;
		transition: padding $t, border $t;
		&:last-child {
			margin-bottom: 0;
		}
	}

	// States
	&.is-more {
		padding: 1.25rem;
		border: 0.0625rem solid $colorBd;
	}
}

.row-main {
	@include clearfix();
	position: relative;
	max-width: $rowWidth;
	margin: 0 auto;
	padding: 0 $gutter;
}

.icon-svg {

	// Variants
	&--pdf,
	&--doc,
	&--xls {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		color: $colorText;
	}
	&--search {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1.5rem;
		height: 1.5rem;
	}
	&--close {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1.25rem;
		height: 1.25rem;
	}
	&--tooth {
		position: absolute;
		top: 50%;
		left: 1.25rem;
		transform: translateY(-50%);
	}
	&--file {
		position: absolute;
		top: 50%;
		right: 1.875rem;
		transform: translateY(-50%);
		width: 1rem;
	}
	&--facebook,
	&--twitter,
	&--linkedin {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 0.875rem;
	}
	&--logout {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 0.875rem;
	}

	// Media
	@media ($lgDown) {
		&--logout {
			display: block;
		}
		&--search,
		&--close {
			width: 0.875rem;
			height: 0.875rem;
		}
	}
}

.inp-checkbox {
	position: absolute;
	left: -312.5rem;
}



%inp {
	display: block;
	width: 100%;
	padding: 1.25rem 1.875rem;
	border: 0.0625rem solid $colorBd;
	border-radius: 0;
	background-color: $colorBg;
	color: $colorPrimary;
	font-size: 1rem;
	line-height: 1.5rem;
	transition: background-color $t, border-color $t;
	-webkit-appearance: none;
	appearance: none;
	outline: none;

	// States
	&:disabled {
		background-color: $colorTertiary;
		color: $colorSecondary;
	}
	&:focus {
		border-color: $colorPrimary;
	}
	
	.has-error & {
		border-color: $colorRed;
		color: $colorRed;
	}
}

.inp-file {
	@extend %inp;

	position: relative;
	height: 4.125rem;
	overflow: hidden;
	&::before {
		content: attr(data-text);
		position: absolute;
		top: 50%;
		left: 1.875rem;
		transform: translateY(-50%);
		color: rgba($colorPrimary, 0.5);
		font-size: 1rem;
		line-height: 1.5rem;
		text-overflow: ellipsis;
	}
	&__inp {
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 10;
		width: 125rem;
		margin-left: -62.5rem;
		font-size: 6.25rem;
		line-height: 12.5rem;
		opacity: 0;
		cursor: pointer;
	}

	// States
	&.is-focus {
		border-color: $colorPrimary;
	}
	&.is-set {
		&::before {
			color: $colorText;
		}
	}
}

.inp-fix {
	position: relative;
	display: block;

	// Variants
	&--profile {
		flex: 0 1 calc(100% - 15rem);
	}
	&--requests {
		flex: 0 1 calc(100% - 15rem);
	}
	&--search {
		position: relative;
		flex: 0 1 auto;
		padding-right: 3.375rem;
	}

	// States
	.has-error & {
		color: $colorRed;
	}
	.has-warning & {
		color: $colorOrange;
	}
	.has-ok & {
		color: $colorGreen;
	}
	.is-open & {
		flex: 0 1 100%;
	}

	// Media
	@media ($lgDown) {
		&--search {
			padding-right: 2.125rem;
		}
	}
}

.inp-label {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 0.25rem;

	// Variants
	&--checkbox {
		display: block;
		width: 100%;
		margin-bottom: 0;
		color: $colorPrimary;
		cursor: pointer;
		span {
			position: relative;
			display: block;
			width: 100%;
			padding: 1.25rem 1.875rem 1.25rem 3.75rem;
			background-color: $colorBg;
			border: 0.0625rem solid $colorBg;
			&::before {
				content: "";
				position: absolute;
				top: 1.3125rem;
				left: 0rem;
				width: 1.25rem;
				height: 1.25rem;
				background-color: $colorWhite;
				border: 0.0625rem solid $colorBd;
			}
			&::after {
				content: "";
				position: absolute;
				top: 1.625rem;
				left: 0.3rem;
				width: 0.625rem;
				height: 0.625rem;
				background-color: $colorPrimary;
				opacity: 0;
				transition: opacity $t;
			}
		}
	}
	&--profile {
		flex: 0 1 15rem;
		margin: 0;
	}
	&--requests {
		flex: 0 1 15rem;
		margin: 0;
	}
	&--checkbox.inp-label--requests {
		flex: 0 1 100%;
	}
	&--filter span {
		padding: 0.625rem 1.875rem 0.625rem 2rem;
		&::before {
			top: 0.75rem;
		}
		&::after {
			top: 1.0625rem;
		}
	}
	// &--filter.inp-label--checkbox span {
	// 	border: 0.0625rem solid $colorTertiary;
	// }
	&--checkbox-table {
		span {
			display: inline-block;
			vertical-align: middle;
			width: 1.25rem;
			height: 1.25rem;
			padding: 0;
			background: none;
			border: none;
			&::before {
				top: 0;
				left: 0;
			}
			&::after {
				top: 0.3125rem;
				left: 0.3125rem;
				width: 0.625rem;
				height: 0.625rem;
			}
		}
	}

	// States
	&--checkbox input:checked + span::after {
		opacity: 1;
	}
}

.inp-select {
	@extend %inp;
	padding-right: 2.5rem;
	background-image: url($svgSelect);
	background-position: top 50% right 0.9375rem;
	background-repeat: no-repeat;
	background-size: 0.625rem 0.3125rem;
	&::-ms-expand {
		display: none;
	}

	// Variants
	&--filter {
		padding: 0.625rem 1.875rem;
		background-color: $colorWhite;
		border: 0.0625rem solid $colorWhite;
	}

	// States
	&--filter {
		&:focus {
			border: 0.0625rem solid $colorPrimary;
		}
	}
}

.inp-text {
	@extend %inp;
	&::placeholder {
		color: rgba($colorPrimary, 0.5);
		opacity: 1;
	}

	// Variants
	&--filter {
		padding: 0.625rem 1.875rem;
		background-color: $colorWhite;
		border: 0.0625rem solid $colorWhite;
	}
	&--profile {
		padding: 1.25rem 1.875rem;
		border: 0.0625rem solid $colorBd;
		background-color: $colorBg;
		line-height: 1.5rem;
	}
	&--requests {
		padding: 1.25rem 1.875rem;
		border: 0.0625rem solid $colorBd;
		background-color: $colorBg;
		line-height: 1.5rem;
	}
	&--search {
		padding: 0.875rem 1.25rem;
	}

	// States
	&:focus {
		&::placeholder {
			color: rgba($colorPrimary, 0.25);
		}
	}
	&--filter {
		&:focus {
			border: 0.0625rem solid $colorPrimary;
		}
	}
	
	@at-root textarea#{&} {
		height: auto;
		resize: vertical;
	}

	// Media
	@media ($lgDown) {
		&--search {
			padding: 0.25rem 1.25rem;
			font-size: 0.75rem;
		}
	}
}

.logo {
	display: block;
	margin: 0;
	padding: 0;
	border: none;
	a {
		display: block;
		&::after {
			display: none;
		}
	}
	&--mobile {
		display: none;
	}

	// Media
	@media ($lgDown) {
		display: none;
		&--mobile {
			display: block;
		}
	}
}

.message {
	padding: $gutter;
	background-color: $colorSecondary;
	> :last-child {
		margin-bottom: 0;
	}
	a {
		color: $colorText;
	}

	// Variants
	&--error {
		background-color: $colorRedLight;
		color: $colorRed;
		li {
			&::before {
				background-color: $colorRed;
			}
		}
		a {
			color: $colorRed;
		}
	}
	&--ok {
		background-color: $colorGreenLight;
		color: $colorGreen;
		a {
			color: $colorGreen;
		}
	}
	&--warning {
		background-color: $colorOrangeLight;
		color: $colorOrange;
		a {
			color: $colorOrange;
		}
	}
	&--flex &__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-left: -1.25rem;
		span {
			flex: 0 1 auto;
			border-left: 1.25rem solid transparent;
		}
	}
}

.row-main {
	@include clearfix();
	position: relative;
	max-width: $rowWidth;
	margin: 0 auto;
	padding: 0 $gutter;
}

.social-icons {
	ul {
		@extend %reset-ul;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-left: -0.0625rem;
	}
	li {
		@extend %reset-ul-li;
		flex: 0 1 auto;
		border-left: 0.0625rem solid transparent;
	}
	&__link {
		position: relative;
		display: block;
		width: 2.125rem;
		height: 2.125rem;
		color: $colorWhite;
		transition: opacity $t;
		&::after {
			display: none;
		}
	}

	// Variants
	&--footer {
		margin-bottom: 1.25rem;
		ul {
			justify-content: flex-start;
		}
	}
	&__link--facebook {
		background-color: $colorFacebook;
	}
	&__link--twitter {
		background-color: $colorTwitter;
	}
	&__link--linkedin {
		background-color: $colorLinkedIn;
	}

	// States
	&__link {
		.hoverevents &:hover {
			opacity: 0.85;
		}
	}
}

.js-toggle {
	position: relative;
	padding-top: 4.125rem;
	&__link {
		position: absolute;
		top: 0;
		left: 0;
		width: 11.875rem;
		height: 2.5rem;
		padding-right: 1.875rem;
		cursor: pointer;
		visibility: hidden;
		opacity: 0;
		transition: visbility $t, opacity $t;
		&::after {
			content: "";
			position: absolute;
			top: 0.4375rem;
			right: 0;
			width: 0;
			height: 0;
		}
	}
	&__link.btn {
		width: auto;
		height: 4.125rem;
		&::after {
			display: none;
		}
	}
	&__content {
		display: none;
	}

	// Variants
	&__link--show {
		&::after {
			border-left: 0.375rem solid transparent;
			border-right: 0.375rem solid transparent;
			border-top: 0.375rem solid $colorPrimary;
		}
	}
	&__link--hide {
		&::after {
			border-left: 0.375rem solid transparent;
			border-right: 0.375rem solid transparent;
			border-bottom: 0.375rem solid $colorPrimary;
		}
	}

	// States
	span {
		&.is-active {
			visibility: visible;
			opacity: 1;
		}
	}
}
