.icon-svg {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 14px;
	&__svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		fill: currentColor;
		pointer-events: none;
		transform: translateZ(0);
	}
	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	&--arrow-right {
		width: 512.002px;
	} 
	&--burger {
		width: 35px;
	} 
	&--close {
		width: 20px;
	} 
	&--dots {
		width: 6px;
	} 
	&--facebook {
		width: 34px;
	} 
	&--first {
		width: 12px;
	} 
	&--last {
		width: 12px;
	} 
	&--linkedin {
		width: 34px;
	} 
	&--logout {
		width: 34px;
	} 
	&--minus {
		width: 24px;
	} 
	&--next {
		width: 6px;
	} 
	&--plus {
		width: 24px;
	} 
	&--prev {
		width: 6px;
	} 
	&--search {
		width: 24px;
	} 
	&--tooth {
		width: 50px;
	} 
	&--twitter {
		width: 34px;
	} 
	&--user {
		width: 34px;
	} 
	&--write {
		width: 18px;
	} 
	
	&--burger::before {
		padding-top: 68.57142857142857%;
	} 
	&--dots::before {
		padding-top: 400%;
	} 
	&--first::before {
		padding-top: 83.33333333333334%;
	} 
	&--last::before {
		padding-top: 83.33333333333334%;
	} 
	&--next::before {
		padding-top: 166.66666666666669%;
	} 
	&--prev::before {
		padding-top: 166.66666666666669%;
	} 
	&--tooth::before {
		padding-top: 140%;
	} 

}