.u-color-primary {
	color: $colorPrimary;
}
.u-color-secondary {
	color: $colorSecondary;
}
.u-color-tertiary {
	color: $colorTertiary;
}

.bg-lightgrey {
	background-color: #E8E8E8;
}
