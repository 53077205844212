.alphabetical {
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
	border-bottom: 0.0625rem solid $colorBd;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: left;
		margin-left: -0.3125rem;
	}
	&__item {
		flex: 0 1 2.5rem;
		border-left: 0.3125rem solid transparent;
		border-bottom: 0.3125rem solid transparent;
		a {
			display: block;
			height: 2.1875rem;
			background-color: $colorBg;
			font-size: 0.875rem;
			line-height: 2.1875rem;
			text-transform: uppercase;
			text-align: center;
			transition: background $t;
			&::after {
				display: none;
			}
		}

		&--active {
			a {
				color: $colorSecondary;
				background-color: $colorPrimary;
			}
		}
	}

	// States
	.hoverevents & {
		a:hover {
			color: $colorSecondary;
			background-color: $colorPrimary;
		}
	}
}

.box-detail {
	h1 {
		font-size: 1.875rem;
		font-weight: $semiBold;
		color: $colorText;
		border: none;
	}
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: space-between;
		margin-left: -2.5rem;
	}
	&__item {
		flex: 0 1 50%;
		border-left: 2.5rem solid transparent;
		border-bottom: 2.5rem solid transparent;
	}
	&__date {
		margin-bottom: 0.625rem;
		font-size: 0.875rem;
	}
	&__wrap {
		margin-bottom: 0;
	}
	&__image {
		position: relative;
		display: block;
		margin-bottom: 0;
		padding-top: 75%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__map {
		position: relative;
		display: block;
		width: 100%;
		padding-top: 75%;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}
	&__info {
		height: calc(100% - 1.9375rem);
		padding: 1.25rem;
		background-color: $colorSecondary;
		&:first-child {
			margin-top: 1.9375rem;
		}
		p {
			margin-bottom: 0.3125rem;
		}

		&--second {
			background-color: white;	
		}
	}

	// Media
	@media ($lgDown) {
		h1 {
			font-size: 1.5rem;
		}
		&__item {
			flex: 0 1 100%;
		}
		&__info {
			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.box-files {
	&__title {
		padding: 1.25rem;
		background-color: $colorSecondary;
		color: $colorText;
	}
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		margin-left: -1.25rem;
		margin-bottom: -1.25rem;
	}
	&__item {
		position: relative;
		flex: 0 1 25%;
		padding-top: 0.125rem;
		padding-left: 1.5rem;
		border-left: 1.25rem solid transparent;
		border-bottom: 1.25rem solid transparent;
	}

	// Media
	@media ($lgDown) {
		&__item {
			flex: 0 1 33.33%;
		}
	}

	@media ($mdDown) {
		&__item {
			flex: 0 1 50%;
		}
	}

	@media ($smDown) {
		&__item {
			flex: 0 1 100%;
		}
	}
}

.box-new {
	&__inner {
		display: block;
		text-decoration: none;
		&::after {
			display: none;
		}
		padding: 0 1rem 1rem 1rem;
	}

	&__image {
		position: relative;
		padding-top: calc(480 / 640 * 100%);
		margin: 0 0 0.9375rem;
		overflow: hidden;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform $t;
		}
	}
	h2 {
		margin: 0 0 0.625rem;
	}
	&__date {
		color: $colorText;
		margin-bottom: 0.625rem;
		font-size: 0.875rem;
		$lineHeight: (22 / 14);
		font-weight: $medium;
	}
	p {
		// font-size: 0.875rem;
		font-size: 1rem;
		$lineHeight: (22 / 14);
		color: $colorText;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	// States
	&__inner {
		.hoverevents &:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
}

.box-pager {
	&__inner {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		margin-left: -1.25rem;
	}
	&__item {
		flex: 0 1 auto;
		margin-left: 1.25rem;
	}
	&__btn {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding: 0;
		border: 0;
		background: none;
		text-decoration: none;
		color: $colorWhite;
		cursor: pointer;
		&::after {
			display: none;
		}
		.icon-svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: left $t, right $t;
		}
	}
	&__btn-txt {
		position: relative;
		display: block;
		padding: 0.625rem 1.25rem;
		border: 0.0625rem solid transparent;
		background-color: $colorLink;
		color: $colorWhite;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: center;
		text-decoration: none;
		transition: background $t, border $t, color $t;
	}
	// Variants
	&__btn--prev &__btn-txt {
		padding-left: 2.5rem;
	}
	&__btn--prev {
		.icon-svg {
			left: 1.25rem;
		}
	}
	&__btn--next &__btn-txt {
		padding-right: 2.5rem;
	}
	&__btn--next {
		.icon-svg {
			right: 1.25rem;
		}
	}

	// States
	.hoverevents &__btn &__btn-txt:hover {
		background-color: $colorHover;
		color: $colorPrimary;
	}
	.hoverevents &__btn--prev:hover {
		color: $colorPrimary;
		.icon-svg {
			left: 1rem;
		}
	}
	.hoverevents &__btn--next:hover {
		color: $colorPrimary;
		.icon-svg {
			right: 1rem;
		}
	}
	&__btn:disabled {
		cursor: default;
	}
	&__btn:disabled &__btn-txt {
		background-color: $colorSecondary;
		color: $colorText;
	}
	&__btn--prev:disabled &__btn-txt {
		color: $colorText !important;
	}
	&__btn--prev:disabled {
		color: $colorText !important;
		.icon-svg {
			left: 1.25rem !important;
		}
	}
	&__btn--next:disabled &__btn-txt {
		color: $colorText !important;
	}
	&__btn--next:disabled {
		color: $colorText !important;
		.icon-svg {
			right: 1.25rem !important;
		}
	}

	// Media
	@media ($mdDown) {
		&__btn-txt {
			padding: 0.625rem 1.5rem !important;
			font-size: 0;
		}
	}
}

.box-photogallery {
	display: block;
	position: relative;
	overflow: hidden;
	padding-top: calc(480 / 640 * 100%);

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform $t;
		object-fit: cover;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
		}
	}
}

.box-profile {
	&__wrap {
		padding: 1.25rem;
		border: 0.0625rem solid $colorBd;
	}
	&__label {
		display: block;
		width: 100%;
		height: 35px;
		margin-bottom: 0.5rem;
		border-bottom: 0.0625rem solid transparent;
	}
	&__value {
		display: block;
		width: 100%;
		font-weight: bold;
		height: 35px;
		margin-bottom: 0.5rem;
		border-bottom: 0.0625rem dotted darken($colorBd, 20);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.box-slider {
	max-width: 60rem;
	height: 100%;
	ul {
		@extend %reset-ul;
	}
	li {
		@extend %reset-ul-li;
		display: block !important;
		height: 100%;
		position: relative;
		padding-top: 41.66666666666667%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		span {
			position: absolute;
			left: 0;
			right: 18.75rem;
			bottom: 2.5rem;
			padding: 1.25rem;
			background-color: rgba($colorPrimary, 0.5);
			font-size: 1.75rem;
			font-weight: $medium;
			color: $colorWhite;
			text-transform: uppercase;
		}
	}

	// States
	.no-js & {
		overflow: auto;
		ul {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;
		}
		li {
			flex: 0 0 100%;
			overflow: hidden;
		}
	}
}

.box-table {
	border-top: 0.0625rem solid $colorBd;
	&__row {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		border-bottom: 0.0625rem solid $colorBd;
	}
	&__item {
		flex: 0 1 auto;
		padding: 0.625rem 1.25rem;
		border-left: 0.0625rem solid $colorBd;
		&:first-child {
			flex: 0 1 16.25rem;
		}
		&:last-child {
			flex: 0 1 calc(100% - 16.25rem);
			border-right: 0.0625rem solid $colorBd;
		}
	}
}

.box-tabs {
	&__list {
		position: relative;
		@extend %reset-ul;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		margin-left: -1.25rem;
	}
	&__item {
		@extend %reset-ul-li;
		flex: 0 1 auto;
		border-left: 1.25rem solid transparent;
		border-bottom: 1.25rem solid transparent;
	}
	&__link {
		display: block;
		padding: 1.25rem 1.875rem;
		border: 0.0625rem solid transparent;
		background-color: $colorSecondary;
		color: $colorPrimary;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: center;
		text-decoration: none;
		transition: background $t, border $t, color $t;
		&::after {
			display: none;
		}
	}
	&__fragment {
		display: block;
	}

	// States
	.hoverevents &__link:hover,
	&__link.is-active {
		background-color: $colorTertiary;
	}

	// Media
	@media ($mdDown) {
		&__list {
			flex-wrap: wrap;
		}
		&__item {
			flex: 0 1 100%;
		}
	}
}
