.u-f-s-sm {
	font-size: 0.75rem;
}
.u-f-s-md {
	font-size: 0.875rem;
}
.u-f-s-lg {
	font-size: 1.125rem;
}
.u-f-s-xl {
	font-size: 1.25rem;
}
