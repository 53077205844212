@mixin input() {
	display: inline-block;
	width: 100%;
	padding: 1.25rem 1.875rem;
	// margin-bottom: 2.2rem;
	margin-right: calc(2.2rem - 20px);
	border: solid 1px transparent;
	border-radius: 0;
	font-size: 0.875rem;
	line-height: 1.625;
	// background-color: $colorBg;
	background-color: $input-bg;
	color: $colorPrimary;

	font-size: 1rem;
	line-height: 1.5rem;
	transition: background-color $t, border-color $t;
	appearance: none;
	outline: none;

	&:disabled {
		background-color: $colorTertiary;
		cursor: default;
	}

	&[type="date"] {
		/* 
		 * Kvůli chybě iOS Safari, kde prázdný input je scvrknutý na výšku
		 * Výpočet (font-size * line-height + padding + border)
		 */
		height: calc(1.5rem + 1.25rem + 1px);
	}

	&::placeholder {
		color: rgba($colorPrimary, 0.5);
		opacity: 1;
	}

	&:hover,
	&:focus {
		outline: none !important;
	}

	&:focus {
		border-color: $colorPrimary;

		&::placeholder {
			color: rgba($colorPrimary, 0.25);
		}
	}

	&--error & {
		border-color: $colorRed;
		color: $colorRed;
	}
}

@mixin designed-frame() {
	border-radius: 4rem 1.12rem 4rem 1.12rem;
	box-shadow: 0px 0.69rem 2.81rem rgba(0, 0, 0, 0.11);
}