%reset-ul {
	margin: 0;
}

%reset-ul-li {
	margin: 0;
	padding: 0;
	background: none;
}

%reset-ol {
	@extend %reset-ul;
	counter-reset: none;
}

%reset-ol-li {
	@extend %reset-ul-li;
	position: static;

	&::before {
		content: normal;
		counter-increment: none;
		position: static;
		top: auto;
		left: auto;
	}
}