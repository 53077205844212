@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

/* Theme colors */
$color-text:  black;
$color-body-bg: transparent;
$colorPrimary: #004288;
$color-secondary: #E8E8E8;

$dialog-padding-x: 2.5rem;
$dialog-padding-y: 2.5rem;
$dialog-padding-x-xs: 0.5rem;
$form-item-label-color: $body-color;

// input
$input-padding-x: 0.5rem;
$input-border-width: 1px;
$input-border-radius: 0.2rem;
$input-bg: lighten($colorPrimary, 67);
$input-bg-hover: darken($input-bg, 5%);

$grid-gutter-width: 20px;

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 360px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 1368px;
$grid-breakpoint-xl: 1960px;

$grid-breakpoints: (
	xs: 0,
	sm: 480px,
	md: 750px,
	lg: 1000px,
	xl: 1200px
);

$container-max-widths: (
	xs: 0,
	sm: 440px,
	md: 710px,
	lg: 960px,
	xl: 1160px
);

$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3	
);