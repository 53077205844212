.cross-dentists-alphabetical {
	&__item {
		p {
			margin-bottom: 0;
			font-size: 0.875rem;
		}
	}
}
.cross-dentists-list {
	&__item {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 0.0625rem;
			background-color: $colorBg;
		}
		&:nth-of-type(2n - 1) {
			&::after {
				left: 0.625rem;
			}
		}
		&:nth-of-type(2n) {
			&::after {
				right: 0.625rem;
			}
		}
		&:nth-last-child(2),
		&:nth-last-child(1) {
			&::after {
				display: none;
			}
		}
		h3,
		p {
			margin-bottom: 0.625rem;
		}
		p {
			font-size: 0.875rem;
		}
	}
}

.cross-new-request {
	padding: 2.5rem 0 1.25rem;
	background-color: $colorBg;
	&__category {
		padding: 0 1.25rem;
		ul {
			padding-bottom: 0.9375rem;
			border-bottom: 0.0625rem solid $colorWhite;
		}
		&:last-child ul {
			padding: 0;
			border: none;
		}
		li {
			padding-left: 1.75rem;
			background-repeat: no-repeat;
			background-position: left top;
			background-size: 1.125rem 1.125rem;
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='18px' height='18px' viewBox='0 0 18 18' enable-background='new 0 0 18 18' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23212529' d='M12.1,16.7L12.1,16.7c0,0.1-0.1,0.1-0.1,0.1H1.3c-0.1,0-0.1,0-0.1-0.1V1.3c0-0.1,0.1-0.1,0.1-0.1H12 c0.1,0,0.1,0,0.1,0.1v1.1l1.2-1.1C13.3,0.5,12.7,0,12,0H1.3C0.6,0,0,0.6,0,1.3v15.5C0,17.4,0.6,18,1.3,18H12 c0.7,0,1.3-0.6,1.3-1.3v-5.4l-1.2,1.2V16.7z'/%3E%3Cpath fill='%23212529' d='M2.1,13.6c0,0.3,0.3,0.6,0.6,0.6h2.1c-0.2-0.3-0.3-0.8-0.3-1.2H2.6v0C2.3,13,2.1,13.3,2.1,13.6z'/%3E%3Cpath fill='%23212529' d='M5.1,10.1H2.6c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h2.2L5.1,10.1z'/%3E%3Cpath fill='%23212529' d='M7.2,7.2H2.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6H6L7.2,7.2z'/%3E%3Cpath fill='%23212529' d='M10.2,4.3H2.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6H9L10.2,4.3z'/%3E%3Cpath fill='%23212529' d='M17.3,2.4c-1-1-2.5-1-3.5,0c-0.5,0.5-7,6.9-7.3,7.2C6.4,9.6,6.4,9.7,6.4,9.8l-0.7,3.3 c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2l3.4-0.7c0.1,0,0.2-0.1,0.3-0.2l7.3-7.2C18.2,4.8,18.2,3.3,17.3,2.4z M7,12.5 l0.3-1.4l1.1,1.1L7,12.5z M16.4,4.9l-6.9,6.8L7.8,9.9l6.9-6.8c0.5-0.5,1.4-0.5,1.8,0C16.9,3.7,16.9,4.5,16.4,4.9z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		}
	}
}
